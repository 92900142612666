import React from 'react'
import {Link} from 'react-router-dom'

import './css/effort-tile.css'

const EffortTile = ({effort, top, left, width, height, pxPerDay}) => {
    return (
        <Link to={`${process.env.PUBLIC_URL}/entries/${effort.entries.slice(-1)[0].slug}`}
            className={height < 24 ? "effort-tile effort-tile-small" : "effort-tile"} 
            style={{
                top: `${top}px`,
                right: `${left}%`,
                width: `calc(${width}% - 0.5em)`,
                height: `${height}px`,
                backgroundImage: `url('${effort.project.heroImage ? effort.project.heroImage.url : ""}')`,
            }}
        >
            <div className="effort-tile-inner">
                {window.innerWidth < 1000 ? null : (
                <div>
                    <p>
                        {`${effort.project.name} -`}
                    </p>
                    <p>
                        {effort.summary}
                    </p>
                </div>
                )}
            </div>
        </Link>
    )
}

export default EffortTile;