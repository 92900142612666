import React, {useState, useEffect} from 'react'

import ReactPlayer from 'react-player'

const GalleryImage = ({image, width, active, selected, index, setActive}) => {

    const [element, setElement] = useState(null);

    useEffect(() => {
        if(!image) return;

        if(image.type === "video/mp4") {
            if(active) {
                const imgWidth = width ? width : 650;
                const imgHeight = imgWidth * 0.6;
                setElement(
                    <div>
                        <div className="gallery-image-container gallery-image-active" style={{height: `${imgHeight}`}}>
                            <ReactPlayer 
                                url={image.url}
                                playing={active}
                                loop={true}
                                volume={0}
                                muted={true}
                                controls={true}
                                width="100%"
                                height="100%"
                                playsinline={true}
                            />
                        </div>
                        <figcaption style={{width: `${imgWidth}px`}}>{image.caption}</figcaption>
                    </div>
                );
            } else {
                const baseWidth = width ? width : 650;
                const imgWidth = (baseWidth - 50) / 4 - 5;
                const imgHeight = imgWidth;
                
                setElement(
                    <div 
                    className={"gallery-image-container gallery-image-inactive" + (selected ? " gallery-image-selected" : "")}
                        style={{width: `${imgWidth + 5}px`, height:`${imgHeight+5}px`}}
                        onClick={() => setActive(index)}
                    >
                        <ReactPlayer 
                            url={image.url}
                            playing={active}
                            loop={true}
                            volume={0}
                            muted={true}
                            controls={false}
                            width={`${imgWidth}px`}
                            height={`${imgHeight}px`}
                            playsinline={true}
                        />
                    </div>
                );
            }
        } else {
            if(active) {
                const imgWidth = width ? width : 650;
                const imgHeight = imgWidth * 0.6;
                setElement(
                    <div>
                        <div className="gallery-image-container gallery-image-active" style={{height: `${imgHeight}px`}}>
                            <div>
                                <img src={image.url} alt={image.caption || "Gallery Image"} style={{maxWidth: `${imgWidth}px`, maxHeight: `${imgHeight}px`}}/>
                            </div>
                        </div>
                        <figcaption style={{width: `${imgWidth}px`}}>{image.caption}</figcaption>
                    </div>
                );
            } else {
                const baseWidth = width ? width : 650;
                const imgWidth = (baseWidth - 50) / 4 - 5;
                const imgHeight = imgWidth;
                setElement(
                    <div 
                        className={"gallery-image-container gallery-image-inactive" + (selected ? " gallery-image-selected" : "")}
                        style={{width: `${imgWidth + 5}px`, height:`${imgHeight+5}px`}}
                        onClick={() => setActive(index)}>
                        <img src={image.url} alt={image.caption || "Gallery Image"} style={{maxWidth: `${imgWidth}px`, maxHeight: `${imgHeight}px`}}/>
                    </div>
                )
            }
            
        }
    }, [image, width, active, index, selected, setActive])

    return element;
}

export default GalleryImage;