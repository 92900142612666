import moment from 'moment'
import slugify from 'slugify'

const validImageTypes = ["png", "jpg", "jpeg"];

const WeeklogUtils = {


    getProjectImage : project => {
        if(project.heroImage && project.heroImage.url !== "") return {url:project.heroImage.url, caption: project.name};

        const imageContainingEntries = (!project.entries || project.entries.length === 0) ? [] : project.entries.filter(entry => {
            if(!entry.images || entry.images.length === 0) return false;
            return entry.images.filter(image => validImageTypes.findIndex(type => type === image.url.split(".").pop()) !== -1).length !== 0;
        });
        if(imageContainingEntries.length !== 0) {
            return {url:imageContainingEntries[0].images[0].url, caption:project.name};
        } else {
            return {url:"", caption: project.name}; //todo, placeholder or something?
        }
    },

    getEntryEffort : (entry, efforts) => {
        if(!efforts || efforts.length === 0) return null;

        const entryMoment = moment(entry.timestamp, "x");
        const validEffort = efforts.find(effort => {
            const startMoment = moment(effort.startDate, "x").startOf("day");
            if(entryMoment.isBefore(startMoment)) return false;
            if(!effort.endDate) return true;
            return entryMoment.isBefore(moment(effort.endDate, "x").endOf("day"));
        })

        return validEffort || null;
    },

    getEffortEntries : (effort, entries) => {
        if(!entries || entries.length === 0) return null;

        const startMoment = moment(effort.startDate, "x");
        const endMoment = effort.endDate ? moment(effort.endDate, "x") : moment();

        return entries
            .filter(e => moment(e.timestamp, "x").isBetween(startMoment, endMoment))
            .sort((a, b) => (b.timestamp - a.timestamp));
    },

    getStatusColor : status => {
        switch(status) {
            case "experimentation":
                return "#BFB662";
            case "stabilization":
                return "#D26A83";
            case "creation":
                return "#B294E6";
            case "elaboration":
                return "#78B6BE";
            case "preparation":
                return "#6FC072";
            default:
                return "#A3A3A3";
        }
    },

    isStatusActive : status => {
        if(status === "experimentation") return true;
        if(status === "stabilization") return true;
        if(status === "creation") return true;
        if(status === "elaboration") return true;
        if(status === "preparation") return true;

        return false;
    },

    getRgbString : (hex, alpha) => {
        var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return `rgb(${parseInt(result[1], 16)}, ${parseInt(result[2], 16)}, ${parseInt(result[3], 16)}, ${alpha})`;
    },

    capitalize : string => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    },

    isStable : status => {
        switch(status) {
            case "concept":
                return true;
            case "release":
                return true;
            case "experiment":
                return true;
            case "prerelease":
                return true;
            case "prototype":
                return true;
            case "experimentation":
                return false;
            case "stabilization":
                return false;
            case "preparation":
                return false;
            case "elaboration":
                return false;
            case "creation":
                return false;
            case "origination":
                return false;
            default:
                console.log("Unexpected status " + status);
                return false;
        }
    },

    getUniqueSlug : (data, name, project, currentId) => {
        let slug = slugify(name, {lower: true, strict: true});
        const takenSlugs = data.projects.filter(p => p.id !== currentId && p.slug.slice(0, slug.length) === slug)
                    .concat(data.entries.filter(e => e.id !== currentId && e.slug.slice(0, slug.length) === slug))
                    .concat(data.efforts.filter(e => e.id !== currentId && e.slug.slice(0, slug.length) === slug));
        if(takenSlugs && takenSlugs.length > 0) {
            slug += "-" + takenSlugs.length;
        }
        return `${project.slug}-${slug}`;
    },

    getUniqueProjectSlug : (data, name, currentId) => {
        let slug = slugify(name, {lower: true, strict: true});
        const takenSlugs = data.projects.filter(p => p.id !== currentId && p.slug.slice(0, slug.length) === slug);
        if(takenSlugs && takenSlugs.length > 0) {
            slug += "-" + takenSlugs.length;
        }
        return slug;
    },

    getUniqueIdeaSlug : (data, name, currentId) => {
        let slug = slugify(name, {lower: true, strict: true});
        const takenSlugs = data.ideas.filter(i => i.id !== currentId && i.slug.slice(0, slug.length) === slug);
        if(takenSlugs && takenSlugs.length > 0) {
            slug += "-" + takenSlugs.length;
        }
        return slug;
    },

    getProjectLastUpdatedTimestamp : (project) => {
        let latestTimestamp = project.timestamp;
        if(project.entries && project.entries.length > 0) {
            latestTimestamp = Math.max(latestTimestamp, project.entries[0].timestamp);
        }
        if(project.efforts && project.efforts.length > 0) {
            latestTimestamp = Math.max(latestTimestamp, project.efforts[0].endDate || project.efforts[0].startDate)
        }

        return latestTimestamp;
    },

    getProjectVersion : (project) => {
        const validEfforts = project.efforts.filter(effort => effort.endDate !== false);
        
        if(validEfforts.length > 0) return validEfforts[0].newVersionNumber;
        else return "0.0.0";
    },

    getPopulatedProject : (project, data) => {
        if(!project) return null;
        return {
            ...project, 
            entries: project.entries && project.entries.length > 0
                ? project.entries.map(e => data.entries[e.index])
                : [],
            efforts: project.efforts && project.efforts.length > 0
                ? project.efforts.map(e => data.efforts[e.index])
                : [],
            downloads: project.downloads && project.downloads.length > 0
                ? project.downloads.map(d => data.downloads[d.index])
                : []
        }
    },

    isProjectEmpty : project => {
        return ((!project.entries || project.entries.length === 0) &&
                (!project.efforts || project.efforts.length === 0))
    },

    getWeeklogWeek : m => {
        const monthHasFiveWeeks = m => {
            const startOfMonth = moment(m).startOf("month");
            const endOfMonth = moment(m).endOf("month");
            return startOfMonth.isoWeekday() > 1 
                && startOfMonth.isoWeekday() < 5 
                && endOfMonth.isoWeekday() >= 4;
        }

        //two edge cases:
        //if the next month begins on the current calendar week's tuesday wednesday or thursday, then we are actually in the first week of the following month
        //if the previous month ends on the current calendar week's thursday, friday or saturday, then we are actually in the final week of the previous month

        const startOfNextMonth = moment(m);
        startOfNextMonth.add(1, "month").startOf("month");
        const endOfWeek = moment(m).endOf("isoWeek");
        if(endOfWeek.isAfter(startOfNextMonth) && startOfNextMonth.isoWeekday() <= 4) {
            //first edge case - i.e. we're in the final three days of a month but are *actally* in the first week of the next month
            return {
                year: startOfNextMonth.get("year"),
                month: startOfNextMonth.get("month"),
                week: 1,
                day: m.isoWeekday(),
                prettyString: `${startOfNextMonth.format("MMMM")} Week 1`,
            }
        }
        
        const endOfPreviousMonth = moment(m);
        endOfPreviousMonth.subtract(1, "month").endOf("month");
        const startOfWeek = moment(m).startOf("isoWeek");
        if(startOfWeek.isBefore(endOfPreviousMonth) && endOfPreviousMonth.isoWeekday() >= 4) {
            //second edge case - i.e. we're in the first three days of a month but are *actually* in the last week of the previous month
            //if the last month starts on a monday and has 30 days, or if it 1starts on a monday or tuesday and has 31 days, then it has 5 weeks
            const startOfPreviousMonth = moment(endOfPreviousMonth);
            startOfPreviousMonth.startOf("month");
            const weekNo = monthHasFiveWeeks(endOfPreviousMonth) ? 5 : 4;
            return {
                year: endOfPreviousMonth.get("year"),
                month: endOfPreviousMonth.get("month"),
                week: weekNo,
                day: m.isoWeekday(),
                prettyString: `${endOfPreviousMonth.format("MMMM")} Week ${weekNo}`,
            }
        }

        //otherwise, it's just a normal day in a normal week, calculate as per normal

        //first, count how many full weeks (jumps of seven days back) we can go before hitting the previous month
        //this is the *maximum* possible value
        let week = 0;
        let simM = moment(m);
        while(simM.month() === m.month()) {
            week++;
            simM.subtract(7, "day");
        }

        const startOfMonth = moment(m).startOf("month");
        if(startOfMonth.isoWeekday() <= 4 && m.isoWeekday() < startOfMonth.isoWeekday()) {
            //if the month starts on a thursday or earlier, then the first week is fractionally allowed to count
            //add one to the week if the current day is before the day the month starts on
            week++;
        } else if(startOfMonth.isoWeekday() > 4 && m.isoWeekday() >= startOfMonth.isoWeekday()) {
            //if the month starts on a friday or later, then the first week shouldn't count
            //subtract one from the week if the current day is on or after the day the month starts on
            week--;
        }

        return {
            year: m.year(),
            month: m.month(),
            week: week,
            day: m.isoWeekday(),
            prettyString: `${m.format("MMMM")} Week ${week}`,
        }
    },

    version: "0.11.5"

};

export default WeeklogUtils;